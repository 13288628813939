import httpUtil from '../utils/httpUtil'


/**
 * 密码登录
 * type : 是否显示loading
 */
 export function fetchLoginByPhone(params,type) {
	return httpUtil.post("/api/crmPc/home/crmPcLoginByPhone", params,type)
}

/**
 * 体验登录
 * type : 是否显示loading
 */
export function fetchLogin(params,type) {
	return httpUtil.post("/api/crmPc/home/crmPcLoginBySms", params,type)
}


/**
 * 获取验证码
 */
export function fetchVeriCode(params,type) {
	return httpUtil.post("/api/crmPc/home/sendSms", params , type)
}

/**
 * 查询公司
 */
export function fetchCompany(params,type) {
	return httpUtil.post("/api/crmPc/home/getCompanyListByPhone", params, type)
}



/**
 * 获取登录code码
 */
 export function fetchCodeUrl(params,type) {
	return httpUtil.post("/api/crmPc/home/getPcLoginImg", params, type)
}

/**
 * 扫码成功后回调码
 */
 export function pcLoginCallBack(params,type) {
	return httpUtil.post("/api/crmPc/home/pcLoginCallBack", params, type)
}

/**
 * 图形验证码
 */
 export function getVerifiCode(params,type) {
	return httpUtil.post("/api/crmPc/home/getVerifiCode", params, type)
}

/**
 * 获取认证签名
 */
 export function forgetPwd(params,type) {
	return httpUtil.post("/api/crmPc/home/forgetPwd", params, type)
}


/**
 * 修改密码
 */
 export function updatePwd(params,type) {
	return httpUtil.post("/api/crmPc/home/updatePwd", params, type)
}










export default {
	fetchLogin,
	fetchVeriCode,
	fetchCompany,
	fetchCodeUrl,
	pcLoginCallBack,
	getVerifiCode,
	forgetPwd,
	updatePwd,
	fetchLoginByPhone
}